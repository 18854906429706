import React, { useEffect, useState } from 'react'
import './index.css'

export default function BouttomScroll() {
    const [showArrow, setShowArrow] = useState(true);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scroll = window.scrollY;

        if (scroll === 0) {
            setShowArrow(true);
        } else {
            setShowArrow(false);
        }
    };
    return (
        <div className="App" style={{ position: "fixed", zIndex: 9 }}>
            <div className={`arrow animated bounce${showArrow ? "" : " fade"}`} />
        </div>
    )
}
